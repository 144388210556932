import Img from "gatsby-image";
import React from "react"
import { Link } from 'react-scroll'
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import SEO from '../components/seo/SEO'
import Layout from '../layout/layout2'
import { Container, Section, media } from "../utils/utils";
import { H2, H1, TextHtml } from "../utils/typo";
import CardSzkolenie from '../components/card/CardSzkolenie'


const BanerWrapper = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  margin-bottom: 2rem;
  
  ${media.lessThan("small")`
    grid-template-columns: 1fr;
    row-gap: 2rem;
    margin-top: 2rem;
    `}
`;

const Image = styled(Img)`
  width: 100%;
  z-index: -1;

  ${down("xs")} {
    display: none;
  }

`;

const Baner = styled.div`
  padding: 2rem;
  align-self: center;
  width: 100%;

  ${down("xs")} {
    margin-right: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const Imagewrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  margin-bottom: 2rem;
  
  ${media.lessThan("small")`
    grid-template-columns: 1fr;
    row-gap: 2rem;
    margin-top: 2rem;
    `}
`;

const Button = styled(Link)`
    display: inline-block;
    width: auto;
    margin-top: 2rem;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    color: ${props => props.theme.color.white};
    text-decoration: none;
    padding: 1rem 3rem;
    background-color: ${props => props.theme.color.baseDark};
    transition: all .4s ease-in-out;

    &:hover {
        color: ${props => props.theme.color.baseDark};
        background-color: ${({ theme }) => theme.color.baseLight};
    }
`;

export const CardWrapper = styled(Container)`
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  margin: 0 auto 0 auto;

  ${up("xl")} {
    grid-template-columns: repeat(4, 1fr);
  }
  
  ${down("lg")} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${down("md")} {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto 2rem auto;
  }

  ${down("sm")} {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto 2rem auto;
  }

  ${down("xs")} {
    grid-template-columns: repeat(1, 1fr);
    margin: 0 auto 2rem auto;
  }
`;

const Szkolenia = ({ data }) => {

  const img = data.datoCmsSzkoleniaPage.mainFoto.fluid
  const szkolenia = data.allDatoCmsSzkoleniaPage.nodes[0].szkolenia
  const zdjecia = data.datoCmsSzkoleniaPage.foto
  const opis = data.datoCmsSzkoleniaPage.opisNode.childMarkdownRemark.html
  const title = data.datoCmsSzkoleniaPage.title

  return <div>
    <SEO
      title='Szkolenia'
      slug='/szkolenia' />
    <Layout>
      <Section pt='0'>
        <BanerWrapper>
          <Baner>
            <H1
              colorFont={props => props.theme.color.baseDark}
              lineHeight='1.4'
              xs='27px'
              s='28px'
              m='29px'
              l='31px'
              xl='33px'
              pb='1rem'
              mt='2rem'>{title}</H1>

            <TextHtml dangerouslySetInnerHTML={{ __html: opis }} />

            {/* <Button
              to='terminarz'
              spy={true} smooth={true}>
              Terminarz szkoleń
            </Button> */}
          </Baner>
          <Image fluid={img}
            alt='Szkolenia - Monika Gierczyńska' />
        </BanerWrapper>
      </Section>

      <Container>
        <Imagewrapper>
          {zdjecia.map((zdj) => (
            <Img
              fluid={zdj.fluid}
              alt='Zdjęcie salonu' />
          ))}
        </Imagewrapper>
      </Container>



      {/* <Section
        bgColor={({ theme }) => theme.color.baseLight}>
        <Container>
          <H3
            colorFont={props => props.theme.color.baseDark}>Szkolenia</H3>
          <Text>Podczas szkoleń stawiam na wysoki poziom zajęć praktycznych, gwarantuję kontakt i opiekę po szkoleniu. Zajęcia prowadzę w grupach dwuosobowych, dzięki czemu moje kursantki mają pewność, że poświęcam im tyle uwagi, ile potrzebują.
          Podczas szkolenia nauczę Cię wykonywać makijaże o jakich marzą klientki. Rozwiń strzydła z Fashion Brows.
          </Text>
        </Container>
      </Section> */}

      <Section>
        <CardWrapper>
          {szkolenia.map((szkolenie) => {
            return (
              <CardSzkolenie
                key={szkolenie.id}
                name={szkolenie.name}
                rodzajSzkolenia={szkolenie.rodzajSzkolenia}
                czasTrwania={szkolenie.czasTrwania}
                uwagi={szkolenie.uwagiNode.childMarkdownRemark.html}
                day1={szkolenie.day1Node.childMarkdownRemark.html}
                day2={szkolenie.day2Node.childMarkdownRemark.html}
                day3={szkolenie.day3Node.childMarkdownRemark.html}
              />
            )
          })}
        </CardWrapper>
      </Section>

      {/* <Section
        bgColor={({ theme }) => theme.color.baseExtraLight}>
        <h3 id='terminarz'>Terminarz szkoleń</h3>
      </Section> */}

    </Layout >
  </div >
}
export default Szkolenia

export const query = graphql`
query {
  datoCmsSzkoleniaPage {
    title
    opisNode {
      childMarkdownRemark {
        html
      }
    }
    mainFoto{
      fluid(maxWidth: 600, forceBlurhash: false, imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
    }
    foto{
      fluid(maxWidth: 600, forceBlurhash: false, imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
    }
  }

  allDatoCmsSzkoleniaPage {
    nodes {
      szkolenia {
        name
        rodzajSzkolenia
        czasTrwania
        id
        day1Node {
          childMarkdownRemark {
            html
          }
        }
        day2Node {
          id
          childMarkdownRemark {
            html
          }
        }
        day3Node {
          childMarkdownRemark {
            html
          }
        }
        uwagiNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
}`
