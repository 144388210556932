import React from "react"
import styled from 'styled-components';
import { H5, TextHtml, Text, Label } from "../../utils/typo";

const Card = styled.div`
    padding: 1rem;
    padding-bottom: 2rem;
    background-color: ${({ theme }) => theme.color.baseExtraLight};

    label {
        text-transform: uppercase;
        font-family: ${({ theme }) => theme.font.main};
        color: ${({ theme }) => theme.color.base};
        font-weight: 500;
        font-size: 15px;
    }

    ${Label} {
        margin-top: 1rem;
    }

    ul {
        list-style-type: circle;
    }
    p, li {
        white-space: pre-wrap;
    }
`;

const CardSzkolenie = (props) => {

    return (
        <Card>
            <label>{props.rodzajSzkolenia}</label>
            <H5
                colorFont={props => props.theme.color.baseDark}
            >{props.name}</H5>

            <p>{props.czasTrwania}</p>
            <TextHtml
                dangerouslySetInnerHTML={{ __html: props.uwagi }}
                lineHeight='1.4'
                xs='15px'
                s='16px'
                m='17px'
                l='17px'
                xl='17px' />
            <Label
                colorFont={props => props.theme.color.baseDark}>{props.day1 === '' ? '' : 'Dzień 1'}</Label>
            <TextHtml
                lineHeight='1.3'
                xs='15px'
                s='16px'
                m='17px'
                l='17px'
                xl='17px'
                dangerouslySetInnerHTML={{ __html: props.day1 }}
            />
            <Label
                colorFont={props => props.theme.color.baseDark}>{props.day2 === '' ? '' : 'Dzień 2'}</Label>
            <TextHtml
                dangerouslySetInnerHTML={{ __html: props.day2 }}
                lineHeight='1.3'
                xs='15px'
                s='16px'
                m='17px'
                l='17px'
                xl='17px' />
            <Label
                colorFont={props => props.theme.color.baseDark}>{props.day3 === '' ? '' : 'Dzień 3'}</Label>
            <TextHtml
                dangerouslySetInnerHTML={{ __html: props.day3 }}
                lineHeight='1.3'
                xs='15px'
                s='16px'
                m='17px'
                l='17px'
                xl='17px' />
        </Card>
    )
}
export default CardSzkolenie